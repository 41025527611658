import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import React from "react";
import TopBarWhite from "../components/common/headers/topbarWhite";
import "../config/firebase";

import "../index.scss";
import "../components/common/index.scss";
import "../index.css";
import "../color16.css";
import "../components/banner/heroBanner.css";
import "../components/banner/serviceHeroBanner.css";
import "../components/blogs/blogSection.css";
import "../components/common/headers/common/logo.css";
import "../components/common/headers/topbarWhite.css";
import "../components/elements/style/bubble.css";
import "../components/elements/style/cat.css";
import "../components/elements/style/rate.css";
import "../components/eventBanner/eventBanner.css";
import "../components/feeback/feedback.css";
import "../components/foster/fosterItem.css";
import "../components/service/searchServiceCollection.css";
import "../components/service/serviceDetail.css";
import "../components/service/serviceItem.css";
import "../components/service/serviceListSection.css";
import "../components/sitter/sitterItem.css";
import "../components/sitter/sitterRecommendCollection.css";
import "../components/video/serviceVideoSection.css";
import "../cra-pages/style/public/notHomePage.css";
import "../cra-pages/style/aboutUs.css";
import "../cra-pages/style/activeSitterList.css";
import "../cra-pages/style/bookingProcess.css";
import "../cra-pages/style/businessContent.css";
import "../cra-pages/style/commonProblem.css";
import "../cra-pages/style/download.css";
import "../cra-pages/style/foster.css";
import "../cra-pages/style/home.css";
import "../cra-pages/style/serviceOnePage.css";
import "../cra-pages/style/services.css";
import "../cra-pages/style/sitterOnePage.css";
import "../places/components/header.css";
import "../components/form/searchServiceButton.css"

if (process.env.NEXT_PUBLIC_APP) {
  Reflect.set(process.env, "PUBLIC_URL", "");
}

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta name="smartbanner:title" content="Fluv毛小愛-寵物保姆平台"/>
        <meta name="smartbanner:author" content="離家最近、最優惠的寵物到府服務"/>
        <meta name="smartbanner:price" content="免費"/>
        <meta name="smartbanner:price-suffix-apple" content=" - On the App Store"/>
        <meta name="smartbanner:price-suffix-google" content=" - In Google Play"/>
        <meta name="smartbanner:icon-apple" content="./fluv-app-icon.png"/>
        <meta name="smartbanner:icon-google" content="./fluv-app-icon.png"/>
        <meta name="smartbanner:button" content="打開"/>
        <meta name="smartbanner:button-url-apple" content="https://apps.apple.com/tw/app/fluv/id1478414083"/>
        <meta name="smartbanner:button-url-google" content="https://play.google.com/store/apps/details?id=com.ifluvyou.fluv"/>
        <meta name="smartbanner:enabled-platforms" content="android,ios"/>
        <meta name="smartbanner:close-label" content="Close"/>
        <link rel="stylesheet" href="./smartbanner.min.css"/>
        <link rel="stylesheet" href="./assets/css/odometer.css"/>
        <link rel="stylesheet" href="./index.css"/>
        <script src="./smartbanner.min.js"></script>
      </Head>
      <TopBarWhite />
      <Component {...pageProps} />
    </>
  );
}

export default appWithTranslation(MyApp);
