import * as types from "constants/ActionTypes";

const globalReducerDefaultState = {
  servicePrices: [],
  serviceExtras: [],
};

const _globalReducer = (state = globalReducerDefaultState, action) => {
  switch (action.type) {
    case types.GET_SERVICE_PRICES:
      return {
        ...state,
        servicePrices: action.servicePrices,
      };
    case types.GET_SERVICE_EXTRAS:
      return {
        ...state,
        serviceExtras: action.serviceExtras,
      };
    default:
      return state;
  }
};

export default _globalReducer;
