import * as types from "constants/ActionTypes";

const initialState = {
  detial: {},
  list: [],
  searchList: [],
  recommendList: [],
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SERVICE_LIST:
      return {
        ...state,
        list: action.services,
      };

    case types.GET_SINGLE_SERVICE:
      if (
        state.list.findIndex((service) => service.id === action.serviceId) !==
        -1
      ) {
        const singleItem = state.list.reduce((itemAcc, service) => {
          return service;
        }, []);
        return {
          ...state,
          detial: singleItem,
        };
      }

    case types.GET_SEARCH_SERVICE:
      return {
        ...state,
        searchList: action.searchServices,
      };

    default:
      return state;
  }
};
export default serviceReducer;
