// Get Sitter
export const GET_SINGLE_SITTER = "GET_SINGLE_SITTER";
export const GET_SITTER_LIST = "GET_SITTER_LIST ";
export const GET_ALL_SITTER_REVIEW = "GET_ALL_SITTER_REVIEW";

// Get Service
export const GET_SINGLE_SERVICE = "GET_SINGLE_SERVICE";
export const GET_SERVICE_LIST = "GET_SERVICE_LIST";
export const GET_SEARCH_SERVICE = "GET_SEARCH_SERVICE";

// Get  Recomment Sitter
export const GET_RECOMMENT_SITTER_LIST = "GET_RECOMMENT_SITTER_LIST";

// Get Profile Reviews
export const GET_PROFILE_REVIEWS = "GET_PROFILE_REVIEWS";

// Filter Sitter
export const FILTER_RATING = "FILTER_RATING";

// Filters Service
export const FILTER_AREA = "FILTER_AREA";
export const FILTER_PET_CATEGORY = "FILTER_PET_CATEGORY";
export const FILTER_SERVICE_CATEGORY = "FILTER_SERVICE_CATEGORY";

// Get Foster
export const GET_FOSTER_LIST = "GET_FOSTER_LIST";

//Get Prices
export const GET_SERVICE_PRICES = "GET_SERVICE_PRICES";

// Get Extras
export const GET_SERVICE_EXTRAS = "GET_SERVICE_EXTRAS";

// Config
export const GET_REGION = "GET_REGION";
