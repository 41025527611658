import storage from "firebase/storage";
import { FIREBASE_STORAGE_BUCKET } from "constants/backend";
import querystring from "query-string";

export const getStoragePublicUrl = (path) => {
  return `https://firebasestorage.googleapis.com/v0/b/${FIREBASE_STORAGE_BUCKET}/o/${encodeURIComponent(
    path
  )}?alt=media`;
};

export const getPictureSize = (width) => {
  const sizes = [1280, 960, 720, 480, 320];
  console.log("width", width);
  // sizes.reduce((result, size) => {
  //   if (size > width) return size;
  //   console.log('result',result);
  //   console.log('size',size);
  //   // return result;
  // }, 0)
};

export const isUri = (uriOrid) => {
  // path could includes `:/`, `://`, `:///`, `:///`
  // https://en.wikipedia.org/wiki/File_URI_scheme#How_many_slashes?
  return uriOrid.includes(":/");
};

/**
 * @function getQueryObj
 *
 * Check query strings from global variable location.search
 * or specified query string (string)
 * and organize the query strings then return an object
 *
 * Examples:
 * location.search      output
 * ------------------------------------------------
 * ?foo=FOO&bar=BAR     { foo: "FOO", bar: "BAR"}
 * ""                   {}
 *
 * @param { string } [locationSearch] - optional customized location.search string (default get from global)
 * @return { object } - the key/value pairs of query strings
 */
export const getQueryObj = (
  locationSearch = (window.location && window.location.search) || ""
) => {
  if (!locationSearch) {
    return {};
  }
  return querystring.parse(locationSearch.slice(1));
};

export const getQueryStr = (param = {}) => {
  const dueParam = Object.keys(param).reduce((prev, currentKey) => {
    const currentValue = param[currentKey];
    if (currentValue === null) {
      return prev;
    }
    return {
      ...prev,
      [`${currentKey}`]: currentValue,
    };
  }, {});
  const qs = querystring.stringify(dueParam);
  if (!qs) {
    return "";
  }
  return `?${qs}`;
};

/**
 * @function getQuery
 *
 * Get value of specific query parameter from global variable location.search
 *
 * Examples:
 * criteria     location.search  output
 * ------------------------------------------------
 * undefined    ?foo=FOO        null
 * foo          ?foo=FOO        FOO
 * foo          ''              undefined
 * foo          ?bar=BAR        undefined
 * ''           ?bar=BAR        null
 * undefined    ?bar=BAR        null
 * ''           ''              null
 * null         '' null         null
 *
 * @param { string } [criteria] - specific query parameter key to find for
 * @param { string } [locationSearch] - optional customized location.search string (default get from global)
 * @return { string } - the value of specific query parameter
 */
export const getQuery = (
  criteria,
  locationSearch = (window.location && window.location.search) || ""
) => {
  if (!criteria) {
    return null;
  }

  return getQueryObj(locationSearch)[criteria];
};

export const setRegionUrl = (region) => {
  const location = window.location;
  const pathNameArray = location.pathname.split("/");
  let newUrlString = location.origin;
  if (pathNameArray[1] === "en" || pathNameArray[1] === "tw") {
    for (let i = 0; pathNameArray.length > i; i++) {
      if (pathNameArray[i] === "en" || pathNameArray[i] === "tw") {
        newUrlString = newUrlString + `${region}`;
      } else {
        newUrlString = newUrlString + "/" + pathNameArray[i];
      }
    }
  }
  window.location.replace(newUrlString);
};
