import React, { useState, useEffect } from "react";
import { Link } from "utils/react-router-dom";
// Data
import localesList from "models/locales.json";

// Translate
import { useTranslation } from "react-i18next";

//region
import { getCookie } from "utils/cookie.js";
import { setRegionUrl } from "../../../utils/url";

const TopBarWhite = () => {
  const { t, i18n } = useTranslation();
  const languageList = localesList;
  const [selectLanguageIndex, setSelectLanguageIndex] = useState(0);

  const checkRegionNumber = region => {
    switch (region) {
      case "tw":
        return 0;
      case "en":
        return 1;
      default:
        break;
    }
  };

  const changeLanguage = (region, index) => {
    setSelectLanguageIndex(index);
    i18n.changeLanguage(`${region}`);
    document.cookie = `region=${region}`;
    setRegionUrl(region);
  };

  // useEffect(() => {
  //   console.log('selectLanguageIndex', selectLanguageIndex);
  // }, [selectLanguageIndex])

  const SocialMedia = () => {
    return (
      <>
        <li className="onhover-div mobile-socialmedia mx-1">
          <a
            href="https://www.facebook.com/fluv.tw"
            title="facebook"
            target="_blank"
          >
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/assets/images/icon/facebook.png`}
              className="img-fluid"
              alt={t("header.alt.facebook")}
            />
          </a>
        </li>
        <li className="onhover-div mobile-socialmedia mx-1">
          <a
            href="https://www.instagram.com/fluv.tw/"
            title="instagram"
            target="_blank"
          >
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/assets/images/icon/instagram.png`}
              className="img-fluid"
              alt={t("header.alt.instagram")}
            />
          </a>
        </li>
        <li className="onhover-div mobile-socialmedia mx-1">
          <a
            href="https://www.youtube.com/channel/UC4DfMPQprKVfKByIWrFo6dA"
            title="youtube"
            target="_blank"
          >
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/assets/images/icon/youtube.png`}
              className="img-fluid"
              alt={t("header.alt.youtube")}
            />
          </a>
        </li>
      </>
    );
  };
  // 多一個下載按鈕
  useEffect(() => {
    const regionNumber = checkRegionNumber(getCookie("region") || "tw");
    setSelectLanguageIndex(regionNumber);
    // i18n.changeLanguage('tw')
  }, []);

  return (
    <div className="top-bar-white mb-3 mb-md-0">
      <div className="top-header white-bg border-bottom-grey">
        <div className="container">
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 text-right">
              <ul className="header-dropdown">
                <SocialMedia />
                <li className="onhover-dropdown">
                  <span>{languageList[selectLanguageIndex].flagIcon}</span>
                  &nbsp;&nbsp;{languageList[selectLanguageIndex].title}
                  <ul className="onhover-show-div">
                    {languageList.map((language, index) => {
                      return (
                        <li key={index}>
                          <span>{language.flagIcon}</span>&nbsp;&nbsp;
                          <a
                            onClick={() => {
                              changeLanguage(language.id, index);
                            }}
                          >
                            {language.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBarWhite;
