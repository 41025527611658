import * as ReactRedux from "react-redux";
import rootReducer from "../reducers";
import allReview from "../models/userReviews.json";

const sitterMock = {
  allReview,
  recommendList: []
};

export const setRecommendSitter = recommendList =>
  (sitterMock.recommendList = recommendList);

export { Provider } from "react-redux";

export const useDispatch = process.env.NEXT_PUBLIC_APP
  ? () => () => {}
  : ReactRedux.useDispatch;

const useSelectorMock = selector => {
  const storeMock = rootReducer({}, "@@INIT");
  Object.assign(storeMock.sitter, sitterMock);
  return selector(storeMock);
};

export const useSelector = process.env.NEXT_PUBLIC_APP
  ? useSelectorMock
  : ReactRedux.useSelector;
